// Here you can add other styles

body {
  font-family: 'Inter', sans-serif;
}

.header-avatar {
  width: 80px;
  border-radius: 50%;
  &-minimized {
    width: 35px;
    border-radius: 50%;
  }
}
.header-title {
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}
.input-append-group {
  position: relative;
  input {
    padding-right: 32px !important;
  }
}
.input-append {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.rad-10 {
  border-radius: 10px;
}
.dropzone-area {
  padding: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  cursor: pointer;
  border: 1px dashed #d8dbe0;
  border-radius: 0.25rem;
  &:hover {
    border: 1px dashed #6a93da;
  }
  &.disabled {
    &:hover {
      border: 1px dashed #d8dbe0;
      cursor: default;
    }
  }
  &.error {
    border: 1px dashed #e55353;
  }
}
.custom-select:disabled {
  opacity: 1;
}
.swal2-popup {
  padding: 0 0 1rem !important;
}
.swal2-actions {
  justify-content: flex-end !important;
  padding: 0 1rem !important;
}
.swal2-confirm {
  padding: 0.375rem 0.75rem !important;
  font-size: 0.875rem !important;
}

.status {
  &-approved {
    padding: 8px 16px;
    background-color: #c3f1c6;
    color: #26912f;
    border-radius: 8px;
    font-weight: bold;
  }
  &-new {
    padding: 8px 16px;
    background-color: #c3e8f1;
    color: #266a91;
    border-radius: 8px;
    font-weight: bold;
  }
}
.ql-editor {
  min-height: 18em;
}

.wyswyg {
  img {
    max-width: 100%;
  }
}

.input-otp {
  height: 60px;
  width: 60px;
  font-weight: bold;
  font-size: 2rem;
  margin-right: 10px;
  text-align: center;
  &:last-child {
    margin-right: 0px;
  }
}

.container-auth {
  background-image: url('./bgImage/bg-auth.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  .main {
    h1 {
      color: #049f99;
    }
    .sub {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      color: #7da19f;
    }
    .title {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #3e4a60;
    }
  }
}

.focus-error {
  .select__control {
    border: 1px solid #e55353;
    border-radius: 4px;
  }
}

.c-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.costom-table {
  width: 100%;
  th,
  td {
    border: 1px solid #cfd2d7;
    padding: 16px 12px;
    color: #687283;
    font-size: 14px;
    line-height: 20px;
  }
  th {
    font-weight: 600;
  }
  td {
    font-weight: 400;
  }
  .empty {
    text-align: center;
    background-color: #f7f7f8;
  }
}

/* Styles go here */

.page-header,
.page-header-space {
  height: 16px;
  width: 100%;
}

.page-footer,
.page-footer-space {
  height: 200px;
  padding-top: 20px;
  margin-top: 50px;
  width: 100%;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
}

.page {
  padding: 32px;
  width: 100%;
  page-break-after: always;
  .logo {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
  }
}

@media print {
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }

  body {
    margin: 0;
  }
}

.preview-img {
  .modal-content {
    .download {
      display: none;
    }
    &:hover {
      .download {
        cursor: pointer;
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #e1e1e126;
        .color-custom {
          color: #898989;
        }
      }
    }
  }
}
